import dynamic from 'next/dynamic';

import { useGlobalConfig } from '../../hooks/configContext';
import BusinessUnitLink from '../../BusinessUnitLink/BusinessUnitLink';

type NavbarProps = {
  children?: React.ReactNode;
};

const LanguageSwitcher = dynamic(() => import('./LanguageSwitcher'), {
  ssr: false,
});

const Navbar = ({ children }: NavbarProps) => {
  const { BU_CONFIG } = useGlobalConfig();

  const isMultiLocaleBusinessUnit = BU_CONFIG.supported_locales.length > 1;

  const shouldLogoOccupyOneThird = children || isMultiLocaleBusinessUnit;

  return (
    <nav
      // Don't change this ID - useElementHeightById relies on it
      id="otovoNavBar"
      className={`z-40 grid w-full items-center bg-white shadow ${
        shouldLogoOccupyOneThird ? 'grid-cols-3' : 'px-8'
      }`}
    >
      <div>{isMultiLocaleBusinessUnit && <LanguageSwitcher />}</div>
      <div className="pb-3 pt-4 text-center">
        <BusinessUnitLink />
      </div>
      <div className="flex items-center justify-end pr-3">{children}</div>
    </nav>
  );
};

export default Navbar;
