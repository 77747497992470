import PoweredByOtovo from '../components/PoweredByOtovo';
import { Cloud$BusinessUnitConfig } from '../types/cloudApi';

type LogoConfig = {
  logoSrc: string;
  style: {
    width?: string;
    height?: string;
    maxHeight?: string;
    minWidth?: string;
  };
  companyName: string;
  storefrontHref: string;
};

type LogoProps = {
  logoSrc: LogoConfig['logoSrc'];
  style: LogoConfig['style'];
  companyName: LogoConfig['companyName'];
};

const BusinessUnitLogo = ({ logoSrc, style, companyName }: LogoProps) => {
  if (companyName === 'Mint') {
    // Temporary workaround - should be gone within a day or two. If not, blame @rix1
    return (
      <div className="flex items-baseline gap-5">
        <img src={logoSrc} alt={`${companyName} logo`} style={{ ...style }} />
        <span className="text-grey-30 block h-2/3 border-l" />
        <PoweredByOtovo />
      </div>
    );
  }

  return <img src={logoSrc} alt={`${companyName} logo`} style={style} />;
};

function getCompanyDefaults(
  buConfig: Cloud$BusinessUnitConfig,
  fallback: string,
) {
  const { matchedPartner } = buConfig;
  if (
    matchedPartner?.type === 'AFFILIATE' &&
    matchedPartner.config.theming_enabled
  ) {
    return {
      id: matchedPartner.config.utm_sources[0],
      companyName: matchedPartner.config.name,
      storefrontHref: fallback,
      logoSrc: matchedPartner.config.logo,
      style: {
        width: 'auto',
        height: '32px',
      },
    };
  }
  return {
    id: buConfig.slug,
    companyName: buConfig.company_name,
    storefrontHref: fallback,
    logoSrc: buConfig.logo,
    style: {
      width: 'auto',
      height: '32px',
    },
  };
}

export function getLogoOverrides(
  buConfig: Cloud$BusinessUnitConfig,
  fallback: string,
): LogoConfig {
  const companyDefaults = getCompanyDefaults(buConfig, fallback);

  switch (companyDefaults.id.toLowerCase()) {
    case 'oktavia-it':
    case 'leroymerlin-it':
    case 'fastweb-it':
      return {
        ...companyDefaults,
        storefrontHref: '#',
      };
    case 'energygo-es':
      return {
        ...companyDefaults,
        style: {
          width: '145px',
          height: 'auto',
        },
      };
    case 'meyerburger-de':
      return {
        ...companyDefaults,
        style: {
          width: '400px',
          height: 'auto',
        },
      };

    case 'imaginaenergia-es':
      return {
        ...companyDefaults,
        style: {
          width: 'auto',
          maxHeight: '54px',
        },
      };
    case 'iberdrola-fr':
      return {
        ...companyDefaults,
        storefrontHref:
          'https://www.iberdrola.fr/particuliers/smart-energies/smart-solaire',
      };
    case 'vivi-it':
      return {
        ...companyDefaults,
        storefrontHref: 'https://www.viviesco.it/',
      };
    case 'lucera-es':
      return {
        ...companyDefaults,
        style: {
          width: 'auto',
          minWidth: '180px',
          maxHeight: '32px',
        },
      };

    case 'masmovil-es':
      return {
        ...companyDefaults,
        style: {
          width: 'auto',
          minWidth: '180px',
          maxHeight: '32px',
        },
      };
    default:
      return companyDefaults;
  }
}

export default BusinessUnitLogo;
