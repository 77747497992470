import Link from 'next/link';

import BusinessUnitLogo, {
  getLogoOverrides,
} from '../BusinessUnitLogo/BusinessUnitLogo';
import { useGlobalConfig } from '../hooks/configContext';

const BusinessUnitLink = () => {
  const { STOREFRONT_URL, BU_CONFIG } = useGlobalConfig();
  const { storefrontHref, style, companyName, logoSrc } = getLogoOverrides(
    BU_CONFIG,
    STOREFRONT_URL,
  );

  return (
    <Link className="flex justify-center" href={storefrontHref}>
      <BusinessUnitLogo
        logoSrc={logoSrc}
        companyName={companyName}
        style={style}
      />
    </Link>
  );
};

export default BusinessUnitLink;
